import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=5b36380a&scoped=true"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"
import style0 from "./Card.vue?vue&type=style&index=0&id=5b36380a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b36380a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/usr/app/components/markdown/Markdown.vue').default,Card: require('/usr/app/components/card/Card.vue').default})
