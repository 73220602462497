
import lang from "~/mixins/lang.js";

import { debounce } from "~/utils/debounce";

export default {
  name: "QrModal",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      show: false,
      desktopView: true,
    };
  },
  created() {
    this.checkStatus();
  },
  mounted() {
    if (process.browser) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener("resize", this.handleResize);
    }
  },
  watch: {
    $route(to) {
      this.checkStatus(to);
    },
  },
  methods: {
    checkStatus(route = this.$route) {
      const { trigId } = route.query || {};
      this.handleQrModal(
        Boolean(trigId && trigId === this.data?.triggerId + "/")
      );
    },
    handleQrModal(status = !this.show) {
      if (!this.desktopView && status && process.browser) {
        return window.location.replace(this.data?.url);
      }
      this.show = status;
      if (status === false && this.$route?.query?.trigId) {
        const query = Object.assign({}, this.$route.query);
        if (query?.trigId === this.data?.triggerId + "/") {
          delete query?.trigId;
          this.$router.replace({ query });
        }
      }
    },
    handleResize: debounce(function (e) {
      this.desktopView = window.innerWidth >= 768;
      if (!this.desktopView) {
        this.handleQrModal(false);
      }
    }, 300),
  },
};
